import useDelivery from './useDelivery'
import useGuest from './useGuest'
import useLabor from './useLabor'
import useLossPrevention from './useLossPrevention'
import useSales from './useSales'

export type IAiSummaryType =
  | 'delivery'
  | 'guest'
  | 'labor'
  | 'lossPrevention'
  | 'sales'

export const hooks = [
  ['sales', useSales],
  ['labor', useLabor],
  ['lossPrevention', useLossPrevention],
  ['guest', useGuest],
  ['delivery', useDelivery],
] as const
