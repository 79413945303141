import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import useBrands from 'pared/layouts/hooks/useBrands'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query listLocationSystemwideOverview(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $iInputParams: JSON!
    $useCustom: Boolean!
  ) {
    listLocationFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        netSales
        netSalesBudget
        netSalesBudgetVariance
        netSalesSssPercent
        grossSales
        grossSalesBudgetVariance
        grossSalesSssPercent
        hourlyLaborCostPercent
        hourlyLaborBudgetVariance
        cogsPercent
        cogsBudgetVariance
        avtPercent
        rcp
        rcpPercent
        rcpBudgetVariance
        inUnitYoyCheckCount
        inUnitYoyCheckCountGrowth
      }
    }

    getCustomizedReport(
      iCustomizedReportName: "LIST_LOCATION_STORES_CUSTOMIZED_EXTEND_TABLE"
      iInputParams: $iInputParams
    ) @include(if: $useCustom) {
      nodes {
        reportName
        reportDefinition
        reportResult
      }
    }
  }
`

export const listLocationSystemwideOverviewConfigs = {
  netSales: 'price',
  netSalesBudget: 'price',
  netSalesBudgetDiff: 'price',
  netSalesBudgetVariance: 'percent',
  netSalesSssPercent: 'percent',
  grossSales: 'price',
  grossSalesBudgetVariance: 'percent',
  grossSalesSssPercent: 'price',
  hourlyLaborCostPercent: 'percent',
  hourlyLaborBudgetVariance: 'percent',
  cogsPercent: 'percent',
  cogsBudgetVariance: 'percent',
  avtPercent: 'percent',
  rcp: 'price',
  rcpPercent: 'percent',
  rcpBudgetVariance: 'percent',
  inUnitYoyCheckCount: 'number',
  inUnitYoyCheckCountGrowth: 'percent',

  // customized
  avgWeeklySales: 'price',

  // customized bbb
  laborPercent: 'percent',
  laborVariance: 'percent',
  fAndPPercent: 'percent',
  fAndPVariance: 'percent',
  ebitdaBeforeBonus: 'price',
  ebitdaBeforeBonusDiff: 'price',
  ebitdaBeforeBonusBudget: 'price',
  ebitdaBeforeBonusPercent: 'percent',
  ebitdaAfterBonusPercent: 'percent',
  grossProfitPercent: 'percent',
  restaurantLevelProfitPercent: 'percent',
  annualizedAvgWeeklySales: 'price',

  // customized mwb
  foodCost: 'percent',
  paperCost: 'percent',
  variableLaborCost: 'percent',
  primeCost: 'percent',
  avtFbDollars: 'price',
  avtFbPercent: 'percent',
  avtPaperDollars: 'price',
  avtPaperPercent: 'percent',
} as const

const USE_CUSTOM = ['bibibop', 'mwb']

const useListLocationSystemwideOverview = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { brand } = useBrands()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: groupFilter?.ids },
      iInputParams: {
        startDate: startDate,
        endDate: endDate,
        locationGroupIds: groupFilter?.ids,
      },
      useCustom: USE_CUSTOM.includes(brand),
    },
    skip: !startDate || !endDate || !groupFilter || !hasGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const listLocationFinancialKpis = data?.listLocationFinancialKpis
        .nodes as
        | {
            locationId: number
          }[]
        | undefined
      const customizedReport = data?.getCustomizedReport?.nodes[0]?.reportResult
        ?.tableData as
        | {
            locationId: number
            netSales: number
            netSalesBudget: number
            netSalesBudgetDiff: number
          }[]
        | undefined

      if (!listLocationFinancialKpis) return null

      return {
        source: listLocationFinancialKpis.map((n) => {
          const groupInfo = groupFilter?.list?.find(
            (l) => l.id === n.locationId,
          )
          const customizedData =
            customizedReport?.find((c) => c.locationId === n.locationId) || {}

          return {
            ...n,
            ...customizedData,
            groupInfo,
          }
        }),
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useListLocationSystemwideOverview
