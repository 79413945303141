import { useMemo } from 'react'

import {
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_90_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'

interface IConfig {
  dateRangeOptions: string[]
  preSelectedDateRange: string
}

const useConfig = (): IConfig => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'mwb':
      case 'burgerworks':
        return {
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
            TYPE_YESTERDAY,
            TYPE_TRAILING_7_DAYS,
            TYPE_TRAILING_90_DAYS,
          ],
          preSelectedDateRange: TYPE_PERIOD,
        }
      case 'ace_pop':
        return {
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
            TYPE_YESTERDAY,
            TYPE_TRAILING_7_DAYS,
          ],
          preSelectedDateRange: TYPE_PERIOD,
        }
      default:
        return {
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
            TYPE_YESTERDAY,
            TYPE_TRAILING_7_DAYS,
          ],
          preSelectedDateRange: TYPE_PERIOD,
        }
    }
  }, [brand])
}

export default useConfig
