import { IApiDataType } from '../types'
import useBbbCompanyFilter from './useBbbCompanyFilter'
import {
  useBbbCorporateFilter,
  useBbbPnlCorporateFilter,
} from './useBbbCorporateFilter'
import {
  useBbbCorporateWithoutBreakdownFilter,
  useBbbPnlCorporateWithoutBreakdownFilter,
} from './useBbbCorporateWithoutBreakdownFilter'
import useBbbExecutiveSummaryFilter from './useBbbExecutiveSummaryFilter'
import useCorporateFilter from './useCorporateFilter'
import useCorporateWithoutBreakdownFilter from './useCorporateWithoutBreakdownFilter'
import useDrgGroupFilter from './useDrgGroupFilter'
import useJscBkGroupFilter from './useJscBkGroupFilter'
import useLfrBrandsGroupFilter from './useLfrBrandsGroupFilter'
import useLfrGfpAllGroupFilter from './useLfrGfpAllGroupFilter'
import useLfrGroupFilter from './useLfrGroupFilter'
import {
  useBbbCompanyLocationFilter,
  useLocationFilter,
} from './useLocationFilter'

export type IApiKeyType = keyof typeof api

const api = {
  lfrGroupFilter: useLfrGroupFilter,
  lfrBrandsGroupFilter: useLfrBrandsGroupFilter,
  corporateFilter: useCorporateFilter,
  locationFilter: useLocationFilter,
  jscBkGroupFilter: useJscBkGroupFilter,
  drgGroupFilter: useDrgGroupFilter,
  lfrGfpAllGroupFilter: useLfrGfpAllGroupFilter,
  corporateWithoutBreakdownFilter: useCorporateWithoutBreakdownFilter,
  bbbCorporateFilter: useBbbCorporateFilter,
  bbbCorporateWithoutBreakdownFilter: useBbbCorporateWithoutBreakdownFilter,
  bbbPnlCorporateFilter: useBbbPnlCorporateFilter,
  bbbPnlCorporateWithoutBreakdownFilter:
    useBbbPnlCorporateWithoutBreakdownFilter,
  bbbCompanyFilter: useBbbCompanyFilter,
  bbbExecutiveSummaryFilter: useBbbExecutiveSummaryFilter,
  bbbCompanyLocationFilter: useBbbCompanyLocationFilter,
}

const useApi = (
  apiName: IApiKeyType,
): {
  data: IApiDataType
  loading: boolean
} => api[apiName]()

export default useApi
