import COLORS from 'pared/constants/colors'
import ExpoAiLogo from 'pared/images/Expo/expoai.png'

import { IConfigsType } from '../../types'

const bibibopConfig: IConfigsType = {
  'bibibop-p-and-l-1-store-eaa772ee': {
    '/:brand/store-opportunities': {
      variables: ['date'],
      groupFilter: {
        api: 'locationFilter',
        autoComplete: true,
        width: 300,
      },
      dateFilter: {
        types: ['period'],
        startDate: '01/01/2024',
        endDate: 'now',
      },
      title: {
        type: 'title',
        title: 'locationInfo',
        subTitle: 'storeSubtitle',
      },
      image: {
        type: 'image',
        src: ExpoAiLogo,
        alt: 'Expo AI',
        height: '40px',
      },
      exrayTitle: {
        type: 'title',
        title: 'Store Level Opportunities',
        titleMargin: '50px 0px 0px',
      },
      exray: {
        type: 'ai-summary',
        total: '',
        background: {
          colors: ['black', 'red'],
          range: [0, 1],
          value: '<%- total >= 0 ? 1 : 0 %>',
        },
        fields: [
          {
            type: 'bbbSales',
            details: [
              {
                key: 'sales',
                api: 'bbbSales',
                columns: [
                  {
                    key: 'name',
                    title: 'Item',
                    sortable: false,
                  },
                  {
                    key: 'value',
                    title: 'Actual Value',
                    align: 'center',
                    sortable: false,
                  },
                  {
                    key: 'budget',
                    title: 'Budget',
                    align: 'center',
                    sortable: false,
                  },
                  {
                    key: 'variance',
                    title: 'Performance vs Budget',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                        sortable: false,
                      },
                      {
                        key: 'variance_pct',
                        title: '%',
                        align: 'center',
                        sortable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'bbbFnp',
            details: [
              {
                key: 'fnp',
                api: 'bbbFnp',
                columns: [
                  {
                    key: 'name',
                    title: 'Item',
                    sortable: false,
                  },
                  {
                    key: 'value',
                    title: 'Actual Value',
                    align: 'center',
                    sortable: false,
                  },
                  {
                    key: 'budget',
                    title: 'Budget',
                    align: 'center',
                    sortable: false,
                  },
                  {
                    key: 'variance',
                    title: 'Performance vs Budget',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                        sortable: false,
                      },
                      {
                        key: 'variance_pct',
                        title: '%',
                        align: 'center',
                        sortable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'bbbLabor',
            details: [
              {
                key: 'labor',
                api: 'bbbLabor',
                columns: [
                  {
                    key: 'name',
                    title: 'Item',
                    sortable: false,
                  },
                  {
                    key: 'value',
                    title: 'Actual Value',
                    align: 'center',
                    sortable: false,
                  },
                  {
                    key: 'budget',
                    title: 'Budget',
                    align: 'center',
                    sortable: false,
                  },
                  {
                    key: 'variance',
                    title: 'Performance vs Budget',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                        sortable: false,
                      },
                      {
                        key: 'variance_pct',
                        title: '%',
                        align: 'center',
                        sortable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'bbbCtrlExpenses',
            details: [
              {
                key: 'ctrlExpenses',
                api: 'bbbCtrlExpenses',
                pageSize: 10,
                columns: [
                  {
                    key: 'name',
                    title: 'Item',
                    sortable: true,
                  },
                  {
                    key: 'value',
                    title: 'Actual Value',
                    align: 'center',
                    sortable: true,
                  },
                  {
                    key: 'budget',
                    title: 'Budget',
                    align: 'center',
                    sortable: true,
                  },
                  {
                    key: 'variance',
                    title: 'Performance vs Budget',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                        sortable: true,
                      },
                      {
                        key: 'variance_pct',
                        title: '%',
                        align: 'center',
                        sortable: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      summary: {
        type: 'typography',
        apiOrContent: 'bbbMissBudget',
      },
    },
    '/:brand/p-and-l': {
      variables: ['bbbPnl'],
      groupFilter: {
        api: 'bbbCompanyLocationFilter',
        autoComplete: true,
        width: 300,
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      title: {
        type: 'title',
        title: ' ',
      },
      filters: {
        type: 'grid',
        children: {
          types: {
            type: 'select',
            value: 'bbbPnl.pnlTypes.value',
            onChange: 'bbbPnl.pnlTypes.onChange',
            options: 'bbbPnl.pnlTypes.options',
            label: 'P&L Type',
          },
          columns: {
            type: 'select',
            value: 'bbbPnl.pnlColumns.value',
            onChange: 'bbbPnl.pnlColumns.onChange',
            options: 'bbbPnl.pnlColumns.options',
            label: 'Columns',
            multiple: true,
          },
        },
      },
      pAndL: {
        type: 'table-v2',
        api: 'bbbPAndL',
        expanded: [
          'total_sales',
          'total_cogs',
          'total_controllable_expenses_with_bank_charges_and_third_party_fees',
          'total_noncontrollable_expenses',
          'total_other',
          'total_taxes',
          'total_restaurant_operating_expenses',
        ],
        columns: [
          {
            key: 'pAndLName',
            title: ' ',
            align: 'left',
            width: '500px',
            fixed: true,
          },
          {
            key: 'Current Sales',
            title: 'Current Sales',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLSales")) %>',
            children: [
              {
                key: 'pAndLSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current Budget',
            title: 'Current Budget',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLBudget")) %>',
            children: [
              {
                key: 'pAndLBudget',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLBudgetPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current Sales vs Budget',
            title: 'Current Sales vs Budget',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLCompare")) %>',
            children: [
              {
                key: 'pAndLCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Prior Year',
            title: 'Prior Year',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYoySales")) %>',
            children: [
              {
                key: 'pAndLYoySales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYoySalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current vs. PY',
            title: 'Current vs. PY',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYoyCompare")) %>',
            children: [
              {
                key: 'pAndLYoyCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYoyComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current YTD',
            title: 'Current YTD',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYtdSales")) %>',
            children: [
              {
                key: 'pAndLYtdSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYtdSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Budget YTD',
            title: 'Budget YTD',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYtdBudget")) %>',
            children: [
              {
                key: 'pAndLYtdBudget',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYtdBudgetPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current YTD vs Budget YTD',
            title: 'Current YTD vs Budget YTD',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYtdCompare")) %>',
            children: [
              {
                key: 'pAndLYtdCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYtdComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Prior YTD',
            title: 'Prior YTD',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYytdSales")) %>',
            children: [
              {
                key: 'pAndLYytdSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYytdSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current YTD vs. Prior YTD',
            title: 'Current YTD vs. Prior YTD',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYytdCompare")) %>',
            children: [
              {
                key: 'pAndLYytdCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYytdComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
        ],
      },
    },
  },
  'bibibop-p-and-l-2-corporate-4096adcd': {
    '/:brand/food-and-paper': {
      variables: [{ type: 'allStores', locationGroupIds: [175] }],
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      bbbFoodAndPaper: {
        type: 'bar-chart',
        title: 'Bibibop Stores - Food/Paper Cost %',
        api: 'bbbFoodAndPaper',
        width: 1500,
        height: 800,
        label: 'location',
        datasets: [
          {
            chartType: 'line',
            key: 'sales',
            label: 'Sales',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
            yAxisID: 'y1',
          },
          {
            key: 'foodAndPaperPercent',
            label: 'Food/Paper Cost',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
        ],
        scaleTypes: {
          y: {
            type: 'percent',
          },
          y1: {
            type: 'price',
          },
        },
        options: {
          scales: {
            x: {
              ticks: {
                font: {
                  size: 10,
                },
              },
            },
            y1: {
              position: 'right',
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      },
    },
    '/:brand/profit-after-controllable': {
      variables: ['allStores'],
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      title: {
        type: 'title',
        title: 'Bibibop Profit After Controllable',
      },
      bbbProfitAfterControllables: {
        type: 'grid',
        children: {
          table: {
            type: 'table-v2',
            api: 'bbbProfitAfterControllables',
            columns: [
              {
                key: '<%- JSON(allStores?.tableColumns) %>',
                hide: '<%- _.isUndefined(allStores) %>',
              },
              {
                key: 'sales',
                title: 'Sales',
                width: '120px',
                align: 'center',
                background: COLORS.Porcelain,
                decimal: 2,
              },
              {
                key: 'profitAfterControllablesAfterDonationPercent',
                title: 'Profit After Controllables (Actual) %',
                width: '120px',
                align: 'center',
              },
              {
                key: 'profitAfterControllablesAfterDonation',
                title: 'Profit After Controllables',
                width: '120px',
                align: 'center',
                background: COLORS.Porcelain,
                decimal: 2,
              },
            ],
          },
          chart: {
            type: 'bar-chart',
            api: 'bbbProfitAfterControllables',
            margin: '70px 0px 0px',
            width: 1100,
            height: 3550,
            label: 'location',
            datasets: [
              {
                key: 'sales',
                label: 'Sales',
                borderColor: COLORS.Basil,
                backgroundColor: COLORS.Basil,
                decimal: 2,
                order: 2,
              },
              {
                chartType: 'line',
                key: 'profitAfterControllablesAfterDonationPercent',
                label: 'Profit After Controllables (Actual) %',
                borderColor: COLORS.Expo,
                backgroundColor: COLORS.Expo,
                xAxisID: 'x1',
                order: 1,
              },
              {
                key: 'profitAfterControllablesAfterDonation',
                label: 'Profit After Controllables',
                borderColor: COLORS.Mango,
                backgroundColor: COLORS.Mango,
                decimal: 2,
                order: 3,
              },
            ],
            scaleTypes: {
              x: {
                type: 'price',
              },
              x1: {
                type: 'percent',
              },
            },
            options: {
              indexAxis: 'y',
              interaction: {
                axis: 'y',
              },
              datasets: {
                bar: {
                  barThickness: 20,
                },
              },
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
                x1: {
                  position: 'top',
                  grid: {
                    drawOnChartArea: false,
                  },
                },
              },
            },
          },
        },
      },
    },
    '/:brand/comp-sales': {
      variables: ['date'],
      table: {
        type: 'table-v2',
        title: 'Bibibop Comp Stores Performance Trends',
        api: 'bbbTrendPeriodMetric',
        columns: [
          {
            key: 'bbbTrendPeriodItmeName',
            title: 'Category',
          },
          {
            key: '<%- JSON(date?.periodCalendar[0].map(t => ({ key: "bbbTrend" + t.id, title: t.id }))) %>',
            hide: '<%- _.isUndefined(date?.periodCalendar[0]) %>',
            align: 'center',
          },
        ],
      },
      chart: {
        type: 'line-chart',
        api: 'bbbTrendPeriodMetric',
        width: 1200,
        height: 300,
        label: 'period',
        datasets: [
          {
            key: 'totalFoodAndPaperPercent',
            label: 'Food & Paper',
            decimal: 2,
          },
          {
            key: 'totalLaborPercent',
            label: 'Labor',
            decimal: 2,
          },
          {
            key: 'totalControllableExpensesWithBankChargesAndThirdPartyFeesPercent',
            label: 'Total Controllable Exp',
            decimal: 2,
          },
          {
            key: 'ebidtaWithBonusPercent',
            label: 'EBITDA after Bonus',
            decimal: 2,
          },
          {
            key: 'restaurantLevelProfitPercent',
            label: 'Restaurant Level Margin',
            decimal: 2,
          },
        ],
        scaleTypes: {
          y: {
            type: 'percent',
          },
        },
      },
    },
    '/:brand/comp-store-sales-trends': {
      variables: ['date'],
      table: {
        type: 'table-v2',
        title: 'Bibibop Comp Store - Sales Trends',
        api: 'bbbAllPeriodMetric',
        columns: [
          {
            key: 'year',
            title: 'Year',
          },
          {
            key: '<%- JSON(date?.periodIds.map(id => ({ key: "compSalesPercent" + id, title: id }))) %>',
            hide: '<%- _.isUndefined(date?.periodIds) %>',
            align: 'center',
          },
        ],
      },
      chart: {
        type: 'bar-chart',
        api: 'bbbAllPeriodMetric',
        width: 1200,
        height: 600,
        label: 'period',
        datasets: [
          {
            key: '<%- JSON(date?.yearIds.map(id => ({ key: "compSalesPercent" + id, label: id }))) %>',
            decimal: 2,
          },
        ],
        scaleTypes: {
          y: {
            type: 'percent',
          },
        },
      },
    },
    '/:brand/corporate-p-and-l': {
      variables: ['bbbPnl'],
      groupFilter: {
        api: 'bbbPnlCorporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      title: {
        type: 'title',
        title: ' ',
      },
      filters: {
        type: 'grid',
        children: {
          types: {
            type: 'select',
            value: 'bbbPnl.pnlTypes.value',
            onChange: 'bbbPnl.pnlTypes.onChange',
            options: 'bbbPnl.pnlTypes.options',
            label: 'P&L Type',
          },
          columns: {
            type: 'select',
            value: 'bbbPnl.pnlColumns.value',
            onChange: 'bbbPnl.pnlColumns.onChange',
            options: 'bbbPnl.pnlColumns.options',
            label: 'Columns',
            multiple: true,
          },
        },
      },
      pAndL: {
        type: 'table-v2',
        api: 'bbbPAndL',
        expanded: [
          'total_sales',
          'total_cogs',
          'total_controllable_expenses_with_bank_charges_and_third_party_fees',
          'total_noncontrollable_expenses',
          'total_other',
          'total_taxes',
          'total_restaurant_operating_expenses',
        ],
        columns: [
          {
            key: 'pAndLName',
            title: ' ',
            align: 'left',
            width: '500px',
            fixed: true,
          },
          {
            key: 'Current Sales',
            title: 'Current Sales',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLSales")) %>',
            children: [
              {
                key: 'pAndLSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current Budget',
            title: 'Current Budget',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLBudget")) %>',
            children: [
              {
                key: 'pAndLBudget',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLBudgetPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current Sales vs Budget',
            title: 'Current Sales vs Budget',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLCompare")) %>',
            children: [
              {
                key: 'pAndLCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Prior Year',
            title: 'Prior Year',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYoySales")) %>',
            children: [
              {
                key: 'pAndLYoySales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYoySalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current vs. PY',
            title: 'Current vs. PY',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYoyCompare")) %>',
            children: [
              {
                key: 'pAndLYoyCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYoyComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current YTD',
            title: 'Current YTD',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYtdSales")) %>',
            children: [
              {
                key: 'pAndLYtdSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYtdSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Budget YTD',
            title: 'Budget YTD',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYtdBudget")) %>',
            children: [
              {
                key: 'pAndLYtdBudget',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYtdBudgetPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current YTD vs Budget YTD',
            title: 'Current YTD vs Budget YTD',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYtdCompare")) %>',
            children: [
              {
                key: 'pAndLYtdCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYtdComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Prior YTD',
            title: 'Prior YTD',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYytdSales")) %>',
            children: [
              {
                key: 'pAndLYytdSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYytdSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current YTD vs. Prior YTD',
            title: 'Current YTD vs. Prior YTD',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYytdCompare")) %>',
            children: [
              {
                key: 'pAndLYytdCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYytdComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
        ],
      },
    },
    '/:brand/company-opportunities': {
      variables: ['date'],
      groupFilter: {
        api: 'bbbPnlCorporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['period'],
        startDate: '01/01/2024',
        endDate: 'now',
      },
      // FIXME: remove after removing group and date filter
      title: {
        type: 'title',
        title: ' ',
      },
      image: {
        type: 'image',
        src: ExpoAiLogo,
        alt: 'Expo AI',
        height: '40px',
      },
      exrayTitle: {
        type: 'title',
        title: 'Company and Region Opportunities',
        titleMargin: '50px 0px 0px',
      },
      exray: {
        type: 'ai-summary',
        total: '',
        background: {
          colors: ['black', 'red'],
          range: [0, 1],
          value: '<%- total >= 0 ? 1 : 0 %>',
        },
        fields: [
          {
            type: 'bbbCorpSales',
            details: [
              {
                key: 'sales',
                api: 'bbbGroupSales',
                pageSize: 10,
                columns: [
                  {
                    key: 'variance',
                    title: 'Performance vs Budget',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                        sortable: true,
                      },
                      {
                        key: 'variance_pct',
                        title: '%',
                        align: 'center',
                        sortable: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'bbbCorpFnp',
            details: [
              {
                key: 'fnp',
                api: 'bbbGroupFnp',
                pageSize: 10,
                columns: [
                  {
                    key: 'variance',
                    title: 'Performance vs Budget',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                        sortable: true,
                      },
                      {
                        key: 'variance_pct',
                        title: '%',
                        align: 'center',
                        sortable: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'bbbCorpLabor',
            details: [
              {
                key: 'labor',
                api: 'bbbGroupLabor',
                pageSize: 10,
                columns: [
                  {
                    key: 'variance',
                    title: 'Performance vs Budget',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                        sortable: true,
                      },
                      {
                        key: 'variance_pct',
                        title: '%',
                        align: 'center',
                        sortable: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'bbbCorpCtrlExpenses',
            details: [
              {
                key: 'ctrlExpenses',
                api: 'bbbGroupCtrlExpenses',
                pageSize: 10,
                columns: [
                  {
                    key: 'variance',
                    title: 'Performance vs Budget',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                        sortable: true,
                      },
                      {
                        key: 'variance_pct',
                        title: '%',
                        align: 'center',
                        sortable: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      summary: {
        type: 'typography',
        apiOrContent: 'bbbMissBudget',
      },
    },
    '/:brand/executive-summary': {
      // Fixed company location group id
      variables: ['date', { type: 'allStores', locationGroupIds: [38] }],
      dateFilter: {
        types: ['period'],
        startDate: '01/01/2024',
        endDate: 'now',
      },
      // FIXME: remove after removing group and date filter
      title: {
        type: 'title',
        title: ' ',
      },
      image: {
        type: 'image',
        src: ExpoAiLogo,
        alt: 'Expo AI',
        height: '40px',
      },
      exrayTitle: {
        type: 'title',
        title: 'Executive Summary',
        titleMargin: '50px 0px 0px',
      },
      exray: {
        type: 'ai-summary',
        total: '',
        background: {
          colors: ['black', 'red'],
          range: [0, 1],
          value: '<%- total >= 0 ? 1 : 0 %>',
        },
        fields: ['bbbExeSales', 'bbbCorpFnp', 'bbbCorpLabor', 'bbbCorpEbitda'],
      },
      summary: {
        type: 'typography',
        apiOrContent: 'bbbMissBudget',
      },
    },
    '/:brand/p-and-l-rankings': {
      variables: ['corporateGroup', { type: 'bbbPnl', withRankOptions: true }],
      groupFilter: {
        api: 'bbbPnlCorporateFilter',
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      title: {
        type: 'title',
        title: 'Ranking',
      },
      filterBy: {
        type: 'grid',
        children: {
          metricCode: {
            type: 'select',
            value: 'bbbPnl.metricCode.value',
            onChange: 'bbbPnl.metricCode.onChange',
            options: 'bbbPnl.metricCode.options',
            label: 'Category',
          },
          rankBy: {
            type: 'select',
            value: 'bbbPnl.rankBy.value',
            onChange: 'bbbPnl.rankBy.onChange',
            options: 'bbbPnl.rankBy.options',
            label: 'Rank By',
          },
        },
      },
      pAndLRankings: {
        type: 'table-v2',
        api: 'bbbPnlRankings',
        columns: [
          {
            key: 'rank',
            title: 'Rank',
            align: 'center',
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(0, 1)) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            width: 'initial',
          },
          {
            key: 'pAndLSales',
            title: 'Actual',
            hide: '<%- bbbPnl.rankBy.value.slice(-1)[0]?.[0] !== "pAndLSales" %>',
            align: 'center',
          },
          {
            key: 'pnlMetrics',
            title: 'Actual vs Budget',
            align: 'center',
            hide: '<%- bbbPnl.rankBy.value.slice(-1)[0]?.[0] !== "pAndLCompare" %>',
            children: [
              {
                key: 'pAndLSales',
                title: '$',
                align: 'center',
                width: '150px',
              },
              {
                key: 'pAndLBudget',
                title: 'Budget $',
                align: 'center',
                width: '150px',
              },
              {
                key: 'pAndLCompare',
                title: 'Difference $',
                align: 'center',
                width: '150px',
              },
              {
                key: 'pAndLComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
              },
            ],
          },
          {
            key: 'pnlMetrics',
            title: 'Actual YTD v Budget YTD',
            align: 'center',
            hide: '<%- bbbPnl.rankBy.value.slice(-1)[0]?.[0] !== "pAndLYtdCompare" %>',
            children: [
              {
                key: 'pAndLYtdSales',
                title: '$',
                align: 'center',
                width: '150px',
              },
              {
                key: 'pAndLYtdBudget',
                title: 'Budget $',
                align: 'center',
                width: '150px',
              },
              {
                key: 'pAndLYtdCompare',
                title: 'Difference $',
                align: 'center',
                width: '150px',
              },
              {
                key: 'pAndLYtdComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
              },
            ],
          },
          {
            key: 'pnlMetrics',
            title: 'Actual v PY',
            align: 'center',
            hide: '<%- bbbPnl.rankBy.value.slice(-1)[0]?.[0] !== "pAndLYoyCompare" %>',
            children: [
              {
                key: 'pAndLYoyCompare',
                title: '$',
                align: 'center',
                width: '150px',
              },
              {
                key: 'pAndLYoyComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
              },
            ],
          },
          {
            key: 'pnlMetrics',
            title: 'Actual YTD v Prior Year YTD',
            align: 'center',
            hide: '<%- bbbPnl.rankBy.value.slice(-1)[0]?.[0] !== "pAndLYytdCompare" %>',
            children: [
              {
                key: 'pAndLYytdCompare',
                title: '$',
                align: 'center',
                width: '150px',
              },
              {
                key: 'pAndLYytdComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
              },
            ],
          },
        ],
      },
    },
  },
  'bibibop-p-and-l-3-1b2342a3': {
    '/:brand/corporate-p-and-l': {
      variables: ['bbbPnl'],
      groupFilter: {
        api: 'bbbCompanyFilter',
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      title: {
        type: 'title',
        title: ' ',
      },
      filters: {
        type: 'grid',
        children: {
          types: {
            type: 'select',
            value: 'bbbPnl.pnlTypes.value',
            onChange: 'bbbPnl.pnlTypes.onChange',
            options: 'bbbPnl.pnlTypes.options',
            label: 'P&L Type',
          },
          columns: {
            type: 'select',
            value: 'bbbPnl.pnlColumns.value',
            onChange: 'bbbPnl.pnlColumns.onChange',
            options: 'bbbPnl.pnlColumns.options',
            label: 'Columns',
            multiple: true,
          },
        },
      },
      pAndL: {
        type: 'table-v2',
        api: 'bbbPAndL',
        expanded: [
          'total_sales',
          'total_cogs',
          'total_controllable_expenses_with_bank_charges_and_third_party_fees',
          'total_noncontrollable_expenses',
          'total_other',
          'total_taxes',
          'total_restaurant_operating_expenses',
        ],
        columns: [
          {
            key: 'pAndLName',
            title: ' ',
            align: 'left',
            width: '500px',
            fixed: true,
          },
          {
            key: 'Current Sales',
            title: 'Current Sales',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLSales")) %>',
            children: [
              {
                key: 'pAndLSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current Budget',
            title: 'Current Budget',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLBudget")) %>',
            children: [
              {
                key: 'pAndLBudget',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLBudgetPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current Sales vs Budget',
            title: 'Current Sales vs Budget',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLCompare")) %>',
            children: [
              {
                key: 'pAndLCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Prior Year',
            title: 'Prior Year',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYoySales")) %>',
            children: [
              {
                key: 'pAndLYoySales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYoySalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current vs. PY',
            title: 'Current vs. PY',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYoyCompare")) %>',
            children: [
              {
                key: 'pAndLYoyCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYoyComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current YTD',
            title: 'Current YTD',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYtdSales")) %>',
            children: [
              {
                key: 'pAndLYtdSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYtdSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Budget YTD',
            title: 'Budget YTD',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYtdBudget")) %>',
            children: [
              {
                key: 'pAndLYtdBudget',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYtdBudgetPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current YTD vs Budget YTD',
            title: 'Current YTD vs Budget YTD',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYtdCompare")) %>',
            children: [
              {
                key: 'pAndLYtdCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYtdComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Prior YTD',
            title: 'Prior YTD',
            align: 'center',
            background: COLORS.Porcelain,
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYytdSales")) %>',
            children: [
              {
                key: 'pAndLYytdSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYytdSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current YTD vs. Prior YTD',
            title: 'Current YTD vs. Prior YTD',
            align: 'center',
            hide: '<%- !bbbPnl.pnlColumns.value.some(v => v.includes("pAndLYytdCompare")) %>',
            children: [
              {
                key: 'pAndLYytdCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYytdComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
        ],
      },
    },
    '/:brand/executive-summary': {
      variables: ['date'],
      groupFilter: {
        api: 'bbbExecutiveSummaryFilter',
      },
      dateFilter: {
        types: ['period'],
        startDate: '01/01/2024',
        endDate: 'now',
      },
      // FIXME: remove after removing group and date filter
      title: {
        type: 'title',
        title: ' ',
      },
      image: {
        type: 'image',
        src: ExpoAiLogo,
        alt: 'Expo AI',
        height: '40px',
      },
      exrayTitle: {
        type: 'title',
        title: 'Executive Summary',
        titleMargin: '50px 0px 0px',
      },
      exray: {
        type: 'ai-summary',
        total: '',
        background: {
          colors: ['black', 'red'],
          range: [0, 1],
          value: '<%- total >= 0 ? 1 : 0 %>',
        },
        fields: ['bbbExeSales', 'bbbCorpFnp', 'bbbCorpLabor', 'bbbCorpEbitda'],
      },
      summary: {
        type: 'typography',
        apiOrContent: 'bbbMissBudget',
      },
    },
  },
}

export default bibibopConfig
