import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BRAND_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import {
  useDateFilter,
  useFirstDateOfYear,
  useYoyDates,
  useYtdEndDate,
} from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const getQuery = (ids: number[]) => gql`
  query lfrListLocationBusinessReview(
    $iBrandId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ${ids.map(
    (id) => `
    $iFilter${id}: JSON!
    $iCustomizedReportInputParams${id}: JSON!
  `,
  )}
    $iYtdStartDate: Date!
    $iYtdEndDate: Date!
  ) {
    brand(id: $iBrandId) {
      id
    }

${ids
  .map(
    (id) => `
    listLocationFinancialKpis${id}: listLocationFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter${id}
    ) {
      nodes {
        locationId
        grossSales
        grossSalesSssPercent
        grossSalesBudgetVariance
        grossSalesBudget
        grossSalesBudgetVarianceAmount
        grossSalesSss
        hourlyLaborCostPercent
        hourlyLaborBudgetVariance
        cogsBudgetVariance
        rcp
        rcpPercent
        rcpBudgetVariance
      }
    }

    ytdListLocationFinancialKpis${id}: listLocationFinancialKpis(
      iStartDate: $iYtdStartDate
      iEndDate: $iYtdEndDate
      iFilter: $iFilter${id}
    ) {
      nodes {
        locationId
        ytdGrossSales: grossSales
      }
    }

    listLocationBusinessReview${id}: listLocationBusinessReview(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter${id}
    ) {
      nodes {
        locationId
        compsPercent
        compsVariance
        revPsf
        covers
        coversTarget
        coversVariance
        coversVariancePercent
        entreeCount
        entreeTarget
        entreeCountVariance
        entreeCountVariancePercent
        transactionCount
        transactionTarget
        transactionCountVariance
        transactionCountVariancePercent
        ppa
        ppaVariance
        ppaVariancePercent
        pea
        peaVariance
        peaVariancePercent
        pta
        ptaVariance
        ptaVariancePercent
        cplh
        foodSalesPercent
        barSalesPercent
        retailSalesPercent
        otherSalesPercent
        lunchDaypart
        dinnerDaypart
        patioReventueCenter
        paidOuts
        supplies
        voids
        controllableComps
        controllableCompsPercent
        corpComps
        eeComps
        marketingComps
        foodCost
        foodCostTarget
        foodCostVariance
        barCost
        barCostTarget
        barCostVariance
        retailCost
        retailCostTarget
        retailCostVariance
        fohLabor
        fohLaborTarget
        fohLaborVariance
        bohLabor
        bohLaborTarget
        bohLaborVariance
        fohOt
        bohOt
        keyEeHourly
        mgmtSalary
        cogsPercent
        laborPercent
      }
    }

    getCustomizedReport${id}: getCustomizedReport(
      iCustomizedReportName: "LIST_LOCATION_STORES_CUSTOMIZED_EXTEND_TABLE"
      iInputParams: $iCustomizedReportInputParams${id}
    ) {
      nodes {
        reportResult
      }
    }
  `,
  )
  .join('\n\n')}
  }
`
const getYoyQuery = (ids: number[]) => gql`
  query yoyLfrListLocationBusinessReview(
    $iBrandId: Int!
  ${ids.map(
    (id) => `
    $iFilter${id}: JSON!
    $iYoyCustomizedReportInputParams${id}: JSON!
  `,
  )}
    $iYoyStartDate: Date!
    $iYoyEndDate: Date!
  ) {
    brand(id: $iBrandId) {
      id
    }

${ids
  .map(
    (id) => `
    yoyListLocationBusinessReview${id}: listLocationBusinessReview(
      iStartDate: $iYoyStartDate
      iEndDate: $iYoyEndDate
      iFilter: $iFilter${id}
    ) {
      nodes {
        locationId
        covers
        entreeCount
        transactionCount
        ppa
        pea
        pta
        primeCostsPercent
      }
    }

    yoyGetCustomizedReport${id}: getCustomizedReport(
      iCustomizedReportName: "LIST_LOCATION_STORES_CUSTOMIZED_EXTEND_TABLE"
      iInputParams: $iYoyCustomizedReportInputParams${id}
    ) {
      nodes {
        reportResult
      }
    }
  `,
  )
  .join('\n\n')}
  }
`

export const lfrListLocationBusinessReviewConfigs = {
  grossSales: 'price',
  grossSalesBudget: 'price',
  grossSalesBudgetVarianceAmount: 'price',
  grossSalesSssPercent: 'percent',
  grossSalesBudgetVariance: 'percent',
  rcp: 'price',
  rcpPercent: 'percent',
  rcpBudgetVariance: 'percent',
  ytdGrossSales: 'price',
  grossSalesSss: 'price',
  hourlyLaborCostPercent: 'percent',
  hourlyLaborBudgetVariance: 'percent',
  covers: 'number',
  coversTarget: 'number',
  coversVariance: 'number',
  coversVariancePercent: 'percent',
  yoyCovers: 'number',
  coversSss: 'number',
  coversSssPercent: 'percent',
  ppa: 'price',
  ppaVariance: 'price',
  ppaVariancePercent: 'percent',
  ppaSss: 'price',
  ppaSssPercent: 'percent',
  cplh: 'number',
  revPsf: 'price',
  compsPercent: 'percent',
  compsVariance: 'percent',
  foodSalesPercent: 'percent',
  barSalesPercent: 'percent',
  retailSalesPercent: 'percent',
  otherSalesPercent: 'percent',
  lunchDaypart: 'percent',
  dinnerDaypart: 'percent',
  patioReventueCenter: 'percent',
  paidOuts: 'price',
  supplies: 'price',
  voids: 'percent',
  controllableComps: 'price',
  controllableCompsPercent: 'percent',
  corpComps: 'price',
  eeComps: 'price',
  marketingComps: 'price',
  foodCost: 'percent',
  foodCostTarget: 'percent',
  foodCostVariance: 'percent',
  barCost: 'percent',
  barCostTarget: 'percent',
  barCostVariance: 'percent',
  retailCost: 'percent',
  retailCostTarget: 'percent',
  retailCostVariance: 'percent',
  fohLabor: 'percent',
  fohLaborTarget: 'percent',
  fohLaborVariance: 'percent',
  bohLabor: 'percent',
  bohLaborTarget: 'percent',
  bohLaborVariance: 'percent',
  fohOt: 'price',
  bohOt: 'price',
  keyEeHourly: 'percent',
  mgmtSalary: 'percent',
  primeCostsPercent: 'percent',
  primeCostsTarget: 'percent',
  primeCostsVariance: 'percent',
  primeCostsPercentSss: 'percent',
  cogsPercent: 'percent',
  cogsBudgetVariance: 'percent',
  laborPercent: 'percent',
  pea: 'price',
  peaVariance: 'price',
  peaVariancePercent: 'percent',
  peaSss: 'price',
  peaSssPercent: 'percent',
  pta: 'price',
  ptaVariance: 'price',
  ptaVariancePercent: 'percent',
  ptaSss: 'price',
  ptaSssPercent: 'percent',
  entreeCount: 'number',
  entreeCountVariance: 'number',
  entreeCountVariancePercent: 'percent',
  entreeCountSss: 'number',
  entreeCountSssPercent: 'percent',
  transactionCount: 'number',
  transactionCountVariance: 'number',
  transactionCountVariancePercent: 'percent',
  transactionCountSss: 'number',
  transactionCountSssPercent: 'percent',
} as const

const getSss = (a?: unknown, b?: unknown) => {
  if (typeof a === 'number' && typeof b === 'number') return a - b

  if (typeof a === 'string' && typeof b === 'string')
    return parseFloat(a) - parseFloat(b)

  return null
}

const getSssPercent = (a?: unknown, b?: unknown) => {
  if (typeof a === 'number' && typeof b === 'number')
    return b ? ((a - b) * 100.0) / b : null

  if (typeof a === 'string' && typeof b === 'string')
    return parseFloat(b)
      ? ((parseFloat(a) - parseFloat(b)) * 100.0) / parseFloat(b)
      : null

  return null
}

const useLfrListLocationBusinessReview = () => {
  const firstDateOfYear = useFirstDateOfYear()
  const ytdEndDate = useYtdEndDate()
  const { startDate, endDate } = useDateFilter()
  const { yoyStartDate, yoyEndDate } = useYoyDates(startDate, endDate)
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { brand } = useBrands()
  const { data, loading } = useQuery(getQuery(groupFilter?.ids || []), {
    variables: {
      ...groupFilter?.ids.reduce(
        (result, id) => ({
          ...result,
          [`iFilter${id}`]: { location_group_ids: [id] },
          [`iCustomizedReportInputParams${id}`]: {
            locationGroupIds: [id],
            startDate,
            endDate,
          },
        }),
        {},
      ),
      iBrandId: BRAND_ID[brand],
      iStartDate: startDate,
      iEndDate: endDate,
      iYtdStartDate: firstDateOfYear,
      iYtdEndDate: ytdEndDate,
    },
    skip: !startDate || !endDate || !hasGroupBy,
  })
  const { data: yoyData, loading: yoyLoading } = useQuery(
    getYoyQuery(groupFilter?.ids || []),
    {
      variables: {
        ...groupFilter?.ids.reduce(
          (result, id) => ({
            ...result,
            [`iFilter${id}`]: { location_group_ids: [id] },
            [`iYoyCustomizedReportInputParams${id}`]: {
              locationGroupIds: [id],
              startDate: yoyStartDate,
              endDate: yoyEndDate,
            },
          }),
          {},
        ),
        iBrandId: BRAND_ID[brand],
        iYoyStartDate: yoyStartDate,
        iYoyEndDate: yoyEndDate,
      },
      skip: !yoyStartDate || !yoyStartDate || !hasGroupBy,
    },
  )

  return {
    data: useMemo((): IApiDataType => {
      const listLocationFinancialKpis = groupFilter?.ids.reduce(
        (result, id) => [
          ...result,
          ...(data?.[`listLocationFinancialKpis${id}`].nodes || []),
        ],
        [] as {
          locationId: number
        }[],
      )

      if (!listLocationFinancialKpis) return null

      const listLocationBusinessReview = groupFilter?.ids.reduce(
        (result, id) => [
          ...result,
          ...(data?.[`listLocationBusinessReview${id}`].nodes || []),
        ],
        [] as {
          locationId: number
          covers: string
          entreeCount: number
          transactionCount: number
          pea: number
          ppa: number
          pta: number
        }[],
      )
      const customizedReportData = groupFilter?.ids.reduce(
        (result, id) => [
          ...result,
          ...(data?.[`getCustomizedReport${id}`].nodes[0].reportResult
            ?.tableData || []),
        ],
        [] as {
          locationId: number
          primeCostsPercent: number
        }[],
      )
      const yoyListLocationBusinessReview = groupFilter?.ids.reduce(
        (result, id) => [
          ...result,
          ...(yoyData?.[`yoyListLocationBusinessReview${id}`].nodes || []),
        ],
        [] as {
          locationId: number
          covers: string
          entreeCount: number
          transactionCount: number
          pea: number
          ppa: number
          pta: number
        }[],
      )
      const yoyCustomizedReportData = groupFilter?.ids.reduce(
        (result, id) => [
          ...result,
          ...(yoyData?.[`yoyGetCustomizedReport${id}`].nodes[0].reportResult
            ?.tableData || []),
        ],
        [] as {
          locationId: number
          primeCostsPercent: number
        }[],
      )
      const ytdlistLocationFinancialKpis = groupFilter?.ids.reduce(
        (result, id) => [
          ...result,
          ...(data?.[`ytdListLocationFinancialKpis${id}`].nodes || []),
        ],
        [] as {
          locationId: number
        }[],
      )

      return {
        source: listLocationFinancialKpis.map((n) => {
          const businessReview = listLocationBusinessReview?.find(
            (c) => c.locationId === n.locationId,
          )
          const customizedData = customizedReportData?.find(
            (c) => c.locationId === n.locationId,
          )
          const yoyBusinessReview = yoyListLocationBusinessReview?.find(
            (c) => c.locationId === n.locationId,
          )
          const yoyCustomizedData = yoyCustomizedReportData?.find(
            (c) => c.locationId === n.locationId,
          )
          const ytdData = ytdlistLocationFinancialKpis?.find(
            (c) => c.locationId === n.locationId,
          )
          const groupInfo = groupFilter?.list?.find(
            (l) => l.id === n.locationId,
          )

          return {
            ...n,
            ...businessReview,
            ...customizedData,
            ...ytdData,
            yoyCovers: yoyBusinessReview?.covers,
            coversSss: getSss(
              businessReview?.covers,
              yoyBusinessReview?.covers,
            ),
            coversSssPercent: getSssPercent(
              businessReview?.covers,
              yoyBusinessReview?.covers,
            ),
            entreeCountSss: getSss(
              businessReview?.entreeCount,
              yoyBusinessReview?.entreeCount,
            ),
            entreeCountSssPercent: getSssPercent(
              businessReview?.entreeCount,
              yoyBusinessReview?.entreeCount,
            ),
            transactionCountSss: getSss(
              businessReview?.transactionCount,
              yoyBusinessReview?.transactionCount,
            ),
            transactionCountSssPercent: getSssPercent(
              businessReview?.transactionCount,
              yoyBusinessReview?.transactionCount,
            ),
            peaSss: getSss(businessReview?.pea, yoyBusinessReview?.pea),
            peaSssPercent: getSssPercent(
              businessReview?.pea,
              yoyBusinessReview?.pea,
            ),
            ppaSss: getSss(businessReview?.ppa, yoyBusinessReview?.ppa),
            ppaSssPercent: getSssPercent(
              businessReview?.ppa,
              yoyBusinessReview?.ppa,
            ),
            ptaSss: getSss(businessReview?.pta, yoyBusinessReview?.pta),
            ptaSssPercent: getSssPercent(
              businessReview?.pta,
              yoyBusinessReview?.pta,
            ),
            primeCostsPercentSss: getSss(
              customizedData?.primeCostsPercent,
              yoyCustomizedData?.primeCostsPercent,
            ),
            groupInfo,
          }
        }),
      }
    }, [groupFilter, data, yoyData]),
    loading: loading || yoyLoading,
  }
}

export default useLfrListLocationBusinessReview
