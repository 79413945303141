import { gql } from '@apollo/client'

export const LOCATION_GUEST_SUMMARY = gql`
  query LocationGuestSummary(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationId: Int!
    $iFilter: JSON!
  ) {
    locationGuestSummary(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iLocationId: $iLocationId
      iFilter: $iFilter
    ) {
      nodes {
        locationInfo {
          id
          code
          name
        }
        positiveWords
        negativeWords
        rankPercent
      }
    }
  }
`

export interface ISummaryNodeType {
  locationInfo: {
    id: number
    code: string
    name: string
  }
  positiveWords?: {
    aspect: string
    adjective: string[]
  }[]
  negativeWords?: {
    aspect: string
    adjective: string[]
  }[]
  rankPercent: number
}

export interface ISummaryType {
  locationGuestSummary: {
    nodes: ISummaryNodeType[]
  }
}

export interface ISummaryVariablesType {
  iLocationId: number
  iStartDate?: string
  iEndDate?: string
  iFilter: {
    review_source: string
  }
}

export const GET_LOCATION_DATA = gql`
  query GetLocationInfo($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`

export const LOCATION_GUEST_SUMMARY_V2 = gql`
  query LocationGuestSummaryV2(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationId: Int!
  ) {
    locationGuestSummaryV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iLocationId: $iLocationId
    ) {
      nodes {
        problemPercentage
        mostCommonProblem
        mostCommonDay
        mostCommonDaypart
      }
    }
  }
`

export const LIST_LOCATION_OPS_COMPS_BREAKDOWN_BY_REASON = gql`
  query ListLocationOpsCompsBreakdownByReason(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationOpsCompsBreakdownByReason(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locationId
        opsCompsReason
        quantity
        totalCompsAmount
      }
    }
  }
`

export const LOCATION_GUEST_KPIS = gql`
  query LocationGuestKpis(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    locationGuestKpis: locationGuestKpisV2(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        reviewSource
        ratingCount
        ratingScore
        ratingRank
        locationCount
      }
    }
  }
`

export const LIST_LOCATION_GUEST_COMMENTS = gql`
  query ListLocationGuestComments(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationGuestComments(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        reviewSource
        reviewContent
        ratingScore
        date
        employeesOnDuty
      }
    }
  }
`

export const LIST_LOCATION_VS_SYSTEMWIDE_MOMENTFEED_SCORE = gql`
  query ListLocationVsSystemwideMomentfeedScore(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
  ) {
    listLocationVsSystemwideMomentfeedScore: listLocationVsSystemwideMomentfeedScoreV2(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        locationId
        businessYear
        businessQuarter
        businessMonth
        businessWeek
        businessDate
        locationRatingScore
        systemwideRatingScore
      }
    }
  }
`

export const LIST_LOCATION_SMG_KPIS = (brand: string) => {
  return gql`
  query ListLocationSmgKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationSmgKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        totalSurveys
        ${
          brand === 'wingitnorth' || brand === 'fw_wingstop'
            ? 'dissatisfactionPercent inaccuratePercent'
            : ''
        }
        ${
          ['mwb', 'burgerworks'].includes(brand)
            ? 'totalOutstandingSurveys overallSatisfaction orderNotOnTime tasteOfFood friendlinessOfEmployees'
            : ''
        }
      }
    }
  }
`
}

export const LIST_LOCATION_GUEST_OPENTABLE_KPIS = gql`
  query ListLocationGuestOpentableKpis(
    $iFilter: JSON!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationGuestOpentableKpis(
      iFilter: $iFilter
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        ratingScore
        ratingCount
        ratingScoreChange
        seatedCovers
        seatedCoversChange
        phoneCovers
        phoneCoversChange
        walkinCovers
        walkinCoversChange
        yourNetworkCovers
        yourNetworkCoversChange
        opentableCovers
        opentableCoversChange
        noShowRate
        noShowRateChange
        ratingRank
      }
    }
  }
`
