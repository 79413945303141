import { IConfigsType } from '../../types'

const bibibopKpis: IConfigsType = {
  'bibibop-kpis-f3e8535d': {
    '/:brand/kpis': {
      groupFilter: {
        api: 'bbbCorporateWithoutBreakdownFilter',
      },
      dateFilter: {
        defaultType: 'this_week',
        types: ['week', 'last_week', 'this_week'],
      },
      downloadKpis: {
        title: 'KPIs',
        type: 'download-csv',
        api: 'bibibopListLocationKpis',
        fileName: 'getexpo-kpis',
        message: 'Download KPIs as CSV',
        fields: [
          'groupInfo',
          {
            key: 'financialScore',
            title: 'Financial',
          },
          {
            key: 'offPremiseScore',
            title: 'Off-Premise',
          },
          {
            key: 'guestScore',
            title: 'Guest',
          },
          {
            key: 'totalScore',
            title: 'Total',
          },
          {
            key: 'overallRank',
            title: 'Overall',
          },
          {
            key: 'sales',
            title: 'Sales Week',
          },
          {
            key: 'eightWeekSalesAvg',
            title: 'Sales 8 Week Avg',
          },
          {
            key: 'salesPercent',
            title: 'Sales %',
          },
          {
            key: 'salesScore',
            title: 'Sales Score',
          },
          {
            key: 'foodAndPaperPercent',
            title: 'F&P Week',
          },
          {
            key: 'foodAndPaperBudgetPercent',
            title: 'F&P Target',
          },
          {
            key: 'foodAndPaperPercentVariance',
            title: 'F&P +/-',
          },
          {
            key: 'foodAndPaperScore',
            title: 'F&P Score',
          },
          {
            key: 'laborPercent',
            title: 'Labor Week',
          },
          {
            key: 'laborBudgetPercent',
            title: 'Labor Budget',
          },
          {
            key: 'laborPercentVariance',
            title: 'Labor +/-',
          },
          {
            key: 'laborScore',
            title: 'Labor Score',
          },
          {
            key: 'cfPercent',
            title: 'CF Week',
          },
          {
            key: 'cfBudgetPercent',
            title: 'CF Budget',
          },
          {
            key: 'cfPercentVariance',
            title: 'CF +/-',
          },
          {
            key: 'cfScore',
            title: 'CF Score',
          },
          {
            key: 'digital',
            title: 'Digital Week',
          },
          {
            key: 'eightWeekDigitalAvg',
            title: 'Digital 8 Week Avg',
          },
          {
            key: 'digitalPercent',
            title: 'Digital +/-',
          },
          {
            key: 'digitalScore',
            title: 'Digital Score',
          },
          {
            key: 'catering',
            title: 'Catering Week',
          },
          {
            key: 'eightWeekCateringAvg',
            title: 'Catering 8 Week Avg',
          },
          {
            key: 'cateringPercent',
            title: 'Catering +/-',
          },
          {
            key: 'cateringScore',
            title: 'Catering Score',
          },
          {
            key: 'checkCount',
            title: 'Weekly Traffic Week',
          },
          {
            key: 'eightWeekCheckCountAvg',
            title: 'Weekly Traffic 8 Week Avg',
          },
          {
            key: 'checkCountPercent',
            title: 'Weekly Traffic +/-',
          },
          {
            key: 'checkCountScore',
            title: 'Weekly Traffic Score',
          },
          {
            key: 'checkAverage',
            title: 'Check Average Week',
          },
          {
            key: 'eightWeekCheckAverageAvg',
            title: 'Check Average 8 Week Avg',
          },
          {
            key: 'checkAveragePercent',
            title: 'Check Average +/-',
          },
          {
            key: 'checkAverageScore',
            title: 'Check Average Score',
          },
          {
            key: 'sixtyTwentyTwo',
            title: '60/22 Week',
          },
          {
            key: 'sixtyTwentyTwoGoal',
            title: '60/22 Goal',
          },
          {
            key: 'sixtyTwentyTwoVariance',
            title: '60/22 +/-',
          },
          {
            key: 'sixtyTwentyTwoScore',
            title: '60/22 Score',
          },
          {
            key: 'stars',
            title: 'Stars / Goal Week',
          },
          {
            key: 'starsGoal',
            title: 'Stars / Goal Goal',
          },
          {
            key: 'starsVariance',
            title: 'Stars / Goal +/-',
          },
          {
            key: 'starsScore',
            title: 'Stars / Goal Score',
          },
          {
            key: 'complaints',
            title: 'Complaints / Goal Week',
          },
          {
            key: 'complaintsGoal',
            title: 'Complaints / Goal Goal',
          },
          {
            key: 'complaintsVariance',
            title: 'Complaints / Goal +/-',
          },
          {
            key: 'complaintsScore',
            title: 'Complaints / Goal Score',
          },
        ],
      },
      kpis: {
        type: 'table',
        api: 'bibibopListLocationKpis',
        groupInfo: {
          width: '250px',
          groupBy: {
            width: '150px',
          },
        },
        defaultSortedField: 'overallRank',
        minWidth: '4000px',
        pageSize: 100,
        columns: [
          {
            key: 'financialScore',
            title: 'Financial',
            align: 'center',
            sortable: true,
            highlight: true,
          },
          {
            key: 'offPremiseScore',
            title: 'Off-Premise',
            align: 'center',
            sortable: true,
          },
          {
            key: 'guestScore',
            title: 'Guest',
            align: 'center',
            sortable: true,
            highlight: true,
          },
          {
            key: 'totalScore',
            title: 'Total',
            align: 'center',
            sortable: true,
          },
          {
            key: 'overallRank',
            title: 'Overall',
            align: 'center',
            sortable: true,
            highlight: true,
          },
          {
            key: 'Sales',
            title: 'Sales',
            align: 'center',
            children: [
              {
                key: 'sales',
                title: 'Week',
                align: 'center',
                sortable: true,
              },
              {
                key: 'eightWeekSalesAvg',
                title: '8 Week Avg',
                align: 'center',
                sortable: true,
              },
              {
                key: 'salesPercent',
                title: '%',
                align: 'center',
                sortable: true,
              },
              {
                key: 'salesScore',
                title: 'Score',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'F&P',
            title: 'F&P',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'foodAndPaperPercent',
                title: 'Week',
                align: 'center',
                sortable: true,
              },
              {
                key: 'foodAndPaperBudgetPercent',
                title: 'Target',
                align: 'center',
                sortable: true,
              },
              {
                key: 'foodAndPaperPercentVariance',
                title: '+/-',
                align: 'center',
                sortable: true,
                reverse: true,
              },
              {
                key: 'foodAndPaperScore',
                title: 'Score',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Labor',
            title: 'Labor',
            align: 'center',
            children: [
              {
                key: 'laborPercent',
                title: 'Week',
                align: 'center',
                sortable: true,
              },
              {
                key: 'laborBudgetPercent',
                title: 'Budget',
                align: 'center',
                sortable: true,
              },
              {
                key: 'laborPercentVariance',
                title: '+/-',
                align: 'center',
                sortable: true,
                reverse: true,
              },
              {
                key: 'laborScore',
                title: 'Score',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'CF',
            title: 'CF',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'cfPercent',
                title: 'Week',
                align: 'center',
                sortable: true,
              },
              {
                key: 'cfBudgetPercent',
                title: 'Budget',
                align: 'center',
                sortable: true,
              },
              {
                key: 'cfPercentVariance',
                title: '+/-',
                align: 'center',
                sortable: true,
              },
              {
                key: 'cfScore',
                title: 'Score',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Digital',
            title: 'Digital',
            align: 'center',
            children: [
              {
                key: 'digital',
                title: 'Week',
                align: 'center',
                sortable: true,
              },
              {
                key: 'eightWeekDigitalAvg',
                title: '8 Week Avg',
                align: 'center',
                sortable: true,
              },
              {
                key: 'digitalPercent',
                title: '+/-',
                align: 'center',
                sortable: true,
                reverse: true,
              },
              {
                key: 'digitalScore',
                title: 'Score',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Catering',
            title: 'Catering',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'catering',
                title: 'Week',
                align: 'center',
                sortable: true,
              },
              {
                key: 'eightWeekCateringAvg',
                title: '8 Week Avg',
                align: 'center',
                sortable: true,
              },
              {
                key: 'cateringPercent',
                title: '+/-',
                align: 'center',
                sortable: true,
                reverse: true,
              },
              {
                key: 'cateringScore',
                title: 'Score',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Weekly Traffic',
            title: 'Weekly Traffic',
            align: 'center',
            children: [
              {
                key: 'checkCount',
                title: 'Week',
                align: 'center',
                sortable: true,
              },
              {
                key: 'eightWeekCheckCountAvg',
                title: '8 Week Avg',
                align: 'center',
                sortable: true,
              },
              {
                key: 'checkCountPercent',
                title: '+/-',
                align: 'center',
                sortable: true,
              },
              {
                key: 'checkCountScore',
                title: 'Score',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Check Average',
            title: 'Check Average',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'checkAverage',
                title: 'Week',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'eightWeekCheckAverageAvg',
                title: '8 Week Avg',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'checkAveragePercent',
                title: '+/-',
                align: 'center',
                sortable: true,
              },
              {
                key: 'checkAverageScore',
                title: 'Score',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: '60/22',
            title: '60/22',
            align: 'center',
            children: [
              {
                key: 'sixtyTwentyTwo',
                title: 'Week',
                align: 'center',
                sortable: true,
                decimal: 0,
              },
              {
                key: 'sixtyTwentyTwoGoal',
                title: 'Goal',
                align: 'center',
                sortable: true,
                decimal: 0,
              },
              {
                key: 'sixtyTwentyTwoVariance',
                title: '+/-',
                align: 'center',
                sortable: true,
                decimal: 0,
              },
              {
                key: 'sixtyTwentyTwoScore',
                title: 'Score',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Stars / Goal',
            title: 'Stars / Goal',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'stars',
                title: 'Week',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'starsGoal',
                title: 'Goal',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'starsVariance',
                title: '+/-',
                align: 'center',
                sortable: true,
                reverse: true,
                decimal: 2,
              },
              {
                key: 'starsScore',
                title: 'Score',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
            ],
          },
          {
            key: 'Complaints / Goal',
            title: 'Complaints / Goal',
            align: 'center',
            children: [
              {
                key: 'complaints',
                title: 'Week',
                align: 'center',
                sortable: true,
              },
              {
                key: 'complaintsGoal',
                title: 'Goal',
                align: 'center',
                sortable: true,
              },
              {
                key: 'complaintsVariance',
                title: '+/-',
                align: 'center',
                sortable: true,
                reverse: true,
              },
              {
                key: 'complaintsScore',
                title: 'Score',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
    },
  },
}

export default bibibopKpis
